type ScreenSize = 'sp' | 'pc';
const pcWidthThreshold = 768;

/**
 * 画面幅の変更によって、PC向けの画面幅なのかSP向けの画面幅なのかを検知するComposable
 */
export function useScreenSize() {
  const currentScreenSize = ref<ScreenSize | undefined>(undefined);
  let mediaQueryList: MediaQueryList | undefined;

  function update(event: MediaQueryListEvent) {
    currentScreenSize.value = event.matches ? 'pc' : 'sp';
  }

  onMounted(() => {
    mediaQueryList = window.matchMedia(`(min-width: ${pcWidthThreshold}px)`);
    currentScreenSize.value = mediaQueryList.matches ? 'pc' : 'sp';

    mediaQueryList.addEventListener('change', update);
  });

  onUnmounted(() => {
    mediaQueryList?.removeEventListener('change', update);
  });

  return {
    screenSize: currentScreenSize,
  };
}
